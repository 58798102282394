import { FC, memo } from 'react'
import {
  Breadcrumb as MikageBreadcrumb,
  BreadcrumbItem
} from '@lifedot/atoms/Breadcrumb'
import { LinkData, useBreadcrumbItemData } from './dependencies'
import { EventTracker } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'

export const Breadcrumb: FC = () => {
  const breadcrumbItemData = useBreadcrumbItemData()

  return (
    <EventTracker
      label="breadCrumb_{innerText}"
      action="click"
      elementCategory={elementCategories.breadcrumb}
      elementLabel="{innerText}"
    >
      <MemorizedBreadcrumb breadcrumbItemData={breadcrumbItemData} />
    </EventTracker>
  )
}

const BeforeMemorizedBreadcrumb: FC<{ breadcrumbItemData: LinkData }> = ({
  breadcrumbItemData
}) => (
  <MikageBreadcrumb>
    {breadcrumbItemData.map(({ href, name }, index) => (
      <BreadcrumbItem key={index} href={href} name={name} />
    ))}
  </MikageBreadcrumb>
)

const MemorizedBreadcrumb = memo(BeforeMemorizedBreadcrumb, () => true)
