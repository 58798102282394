import { FC } from 'react'
import { markup } from '@lifedot/utils'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { StructuredData_cemeteries$key } from './__generated__/StructuredData_cemeteries.graphql'
import { useWebPageDataStructure } from './dependencies'
import {
  OrganizationJsonLd,
  WebPageJsonLd
} from '@lifedot/components/StructuredData'
interface StructuredDataProps {
  prefectureName: string
  cemeteries: StructuredData_cemeteries$key
}

export const StructuredData: FC<StructuredDataProps> = ({
  prefectureName,
  cemeteries
}) => {
  const { items } = useFragment(fragment, cemeteries)
  const webPageDataStructure = useWebPageDataStructure(items)

  const cemeteryJsonLd = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Cemetery',
    name: `${prefectureName}の霊園・墓地の一覧`
  })

  return (
    <>
      <OrganizationJsonLd data-cy="structured-data-organization" />
      <WebPageJsonLd
        {...webPageDataStructure}
        data-cy="structured-data-web-page"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={markup(cemeteryJsonLd)}
        data-cy="structured-data-cemetery"
      />
    </>
  )
}

const fragment = graphql`
  fragment StructuredData_cemeteries on CemeteryResult {
    items {
      updated_at
    }
  }
`
