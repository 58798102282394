/**
 * @generated SignedSource<<2cc174c0d957763476dd0553c663ef5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PromotionBanner_banners$data = {
  readonly items: ReadonlyArray<{
    readonly alt: string | null
    readonly desktopSrc: string | null
    readonly link: string | null
    readonly mobileSrc: string | null
  }>
  readonly ' $fragmentType': 'PromotionBanner_banners'
}
export type PromotionBanner_banners$key = {
  readonly ' $data'?: PromotionBanner_banners$data
  readonly ' $fragmentSpreads': FragmentRefs<'PromotionBanner_banners'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'PromotionBanner_banners',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Banner',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'alt',
          storageKey: null
        },
        {
          alias: 'desktopSrc',
          args: null,
          kind: 'ScalarField',
          name: 'desktop_url',
          storageKey: null
        },
        {
          alias: 'link',
          args: null,
          kind: 'ScalarField',
          name: 'link_url',
          storageKey: null
        },
        {
          alias: 'mobileSrc',
          args: null,
          kind: 'ScalarField',
          name: 'mobile_url',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'BannerResult',
  abstractKey: null
}

;(node as any).hash = 'e5bd9bd0ea59e0225011fa9bf3a0d903'

export default node
