/**
 * @generated SignedSource<<3ed8a5aff276dc338c5652f36326306e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ShopLinksList_Fragment$data = {
  readonly items: ReadonlyArray<{
    readonly prefectureRoma: string
    readonly name: string
    readonly cityId: number
    readonly shopCount: number
  }>
  readonly ' $fragmentType': 'ShopLinksList_Fragment'
}
export type ShopLinksList_Fragment$key = {
  readonly ' $data'?: ShopLinksList_Fragment$data
  readonly ' $fragmentSpreads': FragmentRefs<'ShopLinksList_Fragment'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ShopLinksList_Fragment',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'CityWithShopCount',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'prefectureRoma',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: 'cityId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'shopCount',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CityWithShopCountConnection',
  abstractKey: null
}

;(node as any).hash = '8ea4daa1a05e42d1cd13963a260a9272'

export default node
