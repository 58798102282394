import { FC } from 'react'
import { PartialDisplayAccordion } from '@lifedot/components/PartialDisplayAccordion'
import { GridLinks } from '@lifedot/components/GridLinks'
import { typography } from '@lifedot/styles/typography'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { ConditionLinksList_conditions$key } from './__generated__/ConditionLinksList_conditions.graphql'
import { useConditionLinksItems } from './dependencies'
import { useListPage } from '@/hooks/use-list-page'

interface ConditionLinksProps {
  conditions: ConditionLinksList_conditions$key
}

export const ConditionLinksList: FC<ConditionLinksProps> = ({ conditions }) => {
  const data = useFragment(conditionsFragment, conditions)
  const { prefecture, city, station } = useListPage()
  const items = useConditionLinksItems(data)

  return (
    <PartialDisplayAccordion accordionTitle="お墓の条件を全て表示">
      <h3 css={typography.textM}>
        こだわり条件から
        {city ? city.name : station ? `${station.name}駅周辺` : prefecture.name}
        のお墓を探す
      </h3>
      <GridLinks items={items} />
    </PartialDisplayAccordion>
  )
}

const conditionsFragment = graphql`
  fragment ConditionLinksList_conditions on SearchChoiceWithCemeteryCountConnection {
    items {
      code
      attributeCode
      name
      cemeteryCount
    }
  }
`
