/**
 * @generated SignedSource<<5b770f44aeffdb955c74b38ec0572ea9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type SeoHead_cemeteries$data = {
  readonly items: ReadonlyArray<{
    readonly main_image: {
      readonly path: string | null
    } | null
  }>
  readonly ' $fragmentType': 'SeoHead_cemeteries'
}
export type SeoHead_cemeteries$key = {
  readonly ' $data'?: SeoHead_cemeteries$data
  readonly ' $fragmentSpreads': FragmentRefs<'SeoHead_cemeteries'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'SeoHead_cemeteries',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Cemetery',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'CemeteryImage',
          kind: 'LinkedField',
          name: 'main_image',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'path',
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CemeteryResult',
  abstractKey: null
}

;(node as any).hash = 'fa6ce1b108ce834c4063b81f44329534'

export default node
