import {
  indexSearchChoices,
  SectionTypeCode
} from '@lifedot/constants/searchChoices'
import { router } from '@lifedot/router'
import { ConditionLinksList_conditions$data } from './__generated__/ConditionLinksList_conditions.graphql'
import { useMemo } from 'react'
import { useListPage } from '@/hooks/use-list-page'

// 一般墓は除く(都道府県特集にcanonicalしているため)
const displayAbles = indexSearchChoices
  .map(({ code }) => code)
  .filter((code) => code !== SectionTypeCode.NORMAL)

const makeLinkItem = (
  { code, attributeCode, name }: ConditionLinksList_conditions$data['items'][0],
  prefecture: ReturnType<typeof useListPage>['prefecture'],
  city: ReturnType<typeof useListPage>['city'],
  station: ReturnType<typeof useListPage>['station']
) => {
  const url = city
    ? router.list({
        prefectureRoma: prefecture.roma,
        condition: { code, attributeCode },
        cityId: city.cityId
      })
    : station
    ? router.list({
        prefectureRoma: prefecture.roma,
        condition: { code, attributeCode },
        stationId: station.stationId
      })
    : router.list({
        prefectureRoma: prefecture.roma,
        condition: { code, attributeCode }
      })
  return {
    name,
    url
  }
}

interface ConditionLinkItem {
  name: string
  url: string
}

export const useConditionLinksItems = (
  conditions: ConditionLinksList_conditions$data
): ConditionLinkItem[] => {
  const { prefecture, city, station } = useListPage()

  return useMemo(() => {
    const mapped = conditions.items.reduce<
      Record<string, ConditionLinksList_conditions$data['items'][number]>
    >((res, condition) => ({ ...res, [condition.code]: condition }), {})
    return displayAbles.reduce<ConditionLinkItem[]>((res, code) => {
      const item = mapped[code]
      if (!item || item.cemeteryCount < 1) return res
      return [...res, makeLinkItem(item, prefecture, city, station)]
    }, [])
  }, [city, conditions.items, prefecture, station])
}
