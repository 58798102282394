import { router, urlify } from '@lifedot/router'
import { SectionTypeCode } from '@lifedot/constants/searchChoices'
import { useListPage } from '@/hooks/use-list-page'
import { SeoHead_cemeteries$data } from './__generated__/SeoHead_cemeteries.graphql'
import { replacementImage } from '@lifedot/constants/images'
import { needConditionSuffix } from '@/utils/need-condition-suffix'

export const useHeadText = (): string => {
  const {
    prefecture,
    city,
    condition,
    station,
    pagination: { currentPageNumber },
    total
  } = useListPage()
  const numberOfCount = `${total}件`
  const paging = currentPageNumber > 1 ? `(${currentPageNumber}ページ目)` : ''
  const conditionText = condition
    ? needConditionSuffix(condition.code)
      ? `${condition.name}の`
      : condition.name
    : ''
  const titleText = 'お墓おすすめランキング'
  if (city)
    return `${paging}${city.name}(${prefecture.name})にある${conditionText}${titleText} 霊園・墓地${numberOfCount}掲載！`
  if (station)
    return `${paging}${station.name}駅(${prefecture.name})周辺にある${conditionText}${titleText} 霊園・墓地${numberOfCount}掲載！`
  return `${paging}${prefecture.name}にある${conditionText}${titleText} 霊園・墓地${numberOfCount}掲載！`
}

export const useTitle = (): string => {
  const headText = useHeadText()

  return `${headText}費用相場や口コミも | お墓探しならライフドット`
}

export const useDescription = (): string => {
  const { prefecture, city, condition, station } = useListPage()
  const text = condition
    ? `霊園・墓地の人気ランキングや一覧から`
    : '霊園・墓地の人気ランキング、樹木葬・永代供養墓・納骨堂の一覧から'
  const staticText =
    'ご自宅からの交通アクセス・地図・費用・相場・口コミなど、予算にあわせて種類豊富な条件からお墓探しができます。資料請求・見学予約・石材店の紹介は無料！'
  const suffix = condition
    ? '相談も掲載。'
    : '相談や、市営の公営霊園情報も掲載。'
  const conditionText = condition
    ? needConditionSuffix(condition.code)
      ? `${condition.name}の`
      : condition.name
    : ''

  if (city)
    return `${prefecture.name}${city.name}にある、おすすめの${conditionText}お墓情報。${text}、${staticText}${prefecture.name}${city.name}での墓じまい${suffix}`
  if (station)
    return `${station.name}駅（${prefecture.name}）周辺にある、おすすめの${conditionText}お墓情報。${text}、${staticText}${station.name}駅（${prefecture.name}）周辺での墓じまい${suffix}`
  return `${prefecture.name}にある、おすすめの${conditionText}お墓情報。${text}、${staticText}${prefecture.name}での墓じまい${suffix}`
}

export const useKeyWords = (): string[] => {
  const { prefecture, city, condition, station } = useListPage()

  return [
    prefecture.name,
    city?.name,
    station?.railwayLineName,
    station ? `${station.name}駅` : undefined,
    condition?.name,
    '霊園',
    '墓地',
    'お墓'
  ].filter(
    (item): item is Exclude<typeof item, undefined> => item !== undefined
  )
}

export const useCanonical = (): string => {
  const {
    prefecture,
    city,
    condition,
    station,
    pagination: { currentPageNumber }
  } = useListPage()

  // 条件:一般墓は条件なしに向ける
  if (condition?.code === SectionTypeCode.NORMAL) {
    // 市区町村または駅リストの場合ページネーションは保持
    return urlify(
      router.list({
        prefectureRoma: prefecture.roma,
        cityId: city?.cityId,
        stationId: station?.stationId,
        page: currentPageNumber
      })
    )
  }

  // 駅リスト条件付きは条件なしに向ける & ページネーションは保持しない
  if (station && condition) {
    return urlify(
      router.list({
        prefectureRoma: prefecture.roma,
        stationId: station.stationId
      })
    )
  }

  // それ以外はクエリストリングなしのパスへ向ける & ページネーションは保持
  return urlify(
    router.list({
      prefectureRoma: prefecture.roma,
      cityId: city?.cityId,
      stationId: station?.stationId,
      condition: condition
        ? { attributeCode: condition.attributeCode, code: condition.code }
        : undefined,
      page: currentPageNumber
    })
  )
}

export const useNoIndexNoFollow = (): [boolean, boolean] => {
  const { condition } = useListPage()
  return [!!condition?.noIndex, !!condition?.noIndex]
}

export const makeMetaImage = (
  items: SeoHead_cemeteries$data['items']
): { path: string } => {
  return {
    path: items[0]?.main_image?.path ?? replacementImage.path
  }
}
