import { FC, Children, cloneElement, isValidElement, ReactNode } from 'react'
import { css } from '@emotion/react'
import { PaginationItem, PaginationItemProps } from './PaginationItem'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { mq } from '@lifedot/styles/mediaQuery'

const makePickupAndDecorateElement =
  (arrayChildren: ReactNode[], current: number) => (index: number) => {
    const element = arrayChildren[index]
    if (!isValidElement<PaginationItemProps>(element)) return element
    if (current - 1 === index) return cloneElement(element, { isCurrent: true })
    if (current - 2 === index) return cloneElement(element, { prev: true })
    if (current === index) return cloneElement(element, { next: true })
    return element
  }

const paginationIndexes = (currentIndex: number, endIndex: number) => {
  const left = [0, 1]
  const right = [endIndex - 1, endIndex]
  const center = [currentIndex - 1, currentIndex, currentIndex + 1]
  const indexes = Array.from(new Set([...left, ...center, ...right]))
  const leftIndexes = indexes.splice(0, 2)
  const rightIndexes = indexes.splice(-2)
  const centerIndexes = indexes
  return [leftIndexes, centerIndexes, rightIndexes]
}

const createNewPaginationItems = (children: ReactNode, current: number) => {
  const arrayChildren = Children.toArray(children)
  const [leftIndexes = [], centerIndexes = [], rightIndexes = []] =
    paginationIndexes(current - 1, arrayChildren.length - 1)
  const pickupAndDecorateElement = makePickupAndDecorateElement(
    arrayChildren,
    current
  )
  const leftElements = leftIndexes.map(pickupAndDecorateElement)
  const centerElements = centerIndexes.map(pickupAndDecorateElement)
  const rightElements = rightIndexes.map(pickupAndDecorateElement)
  const prev = arrayChildren[current - 2]
  const prevElement = isValidElement<PaginationItemProps>(prev) ? (
    cloneElement(prev, {
      index: <ChevronLeftIcon fontSize="small" style={{ display: 'block' }} />,
      prev: true
    })
  ) : (
    <></>
  )
  const next = arrayChildren[current]
  const nextElement = isValidElement<PaginationItemProps>(next) ? (
    cloneElement(next, {
      index: <ChevronRightIcon fontSize="small" style={{ display: 'block' }} />,
      next: true
    })
  ) : (
    <></>
  )
  const displayableLeftGap =
    (centerIndexes[0] ?? 0) - (leftIndexes.slice(-1)[0] ?? 0) > 1
  const displayableRightGap =
    (rightIndexes[0] ?? 0) - (centerIndexes.slice(-1)[0] ?? 0) > 1
  return {
    elements: [leftElements, centerElements, rightElements],
    displayableLeftGap,
    displayableRightGap,
    prevElement,
    nextElement
  }
}

const styles = {
  root: css({
    display: 'flex',
    flexWrap: 'wrap',
    [mq('sp')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(30px, 30px))',
      gap: 4
    }
  })
}

export interface PaginationProps {
  current: number
  children?: ReactNode
}

export const Pagination: FC<PaginationProps> = (props) => {
  const { children, current } = props
  const {
    elements: [leftElements, centerElements, rightElements],
    displayableLeftGap,
    displayableRightGap,
    prevElement,
    nextElement
  } = createNewPaginationItems(children, current)

  return (
    <>
      <nav aria-label="ページナビゲーション">
        <ul css={styles.root}>
          {prevElement}
          {leftElements}
          {displayableLeftGap && <PaginationItem index="…" />}
          {centerElements}
          {displayableRightGap && <PaginationItem index="…" />}
          {rightElements}
          {nextElement}
        </ul>
      </nav>
    </>
  )
}
