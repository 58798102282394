import { useAveragePrices_averages$key } from '@lifedot/hooks/use-average-prices/__generated__/useAveragePrices_averages.graphql'
import { usePriceText } from './dependencies'
import { markup } from '@lifedot/utils'

type AveragePriceProps = {
  averages: useAveragePrices_averages$key
}

export const AveragePrice = ({ averages }: AveragePriceProps) => {
  const priceText = usePriceText(averages)

  return <p dangerouslySetInnerHTML={markup(priceText)} />
}
