import { urlify } from '@lifedot/router'
import { StructuredData_cemeteries$data } from './__generated__/StructuredData_cemeteries.graphql'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

const makeItems = ({
  asPath,
  items
}: {
  asPath: string
  items: StructuredData_cemeteries$data['items']
}): { url: string; dateModified?: string } => {
  const url = urlify(asPath)
  const dateModified = items[0]?.updated_at

  return { url, dateModified }
}

export const useWebPageDataStructure = (
  items: StructuredData_cemeteries$data['items']
): ReturnType<typeof makeItems> => {
  const { asPath } = useRouter()

  // URL系の処理はクライアント側とサーバ側で差異が出てしまうため、マウント後に生成する
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return useMemo(
    () => makeItems({ asPath: mounted ? asPath : '', items }),
    [asPath, items, mounted]
  )
}
