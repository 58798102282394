import { FC } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'
import { useAreaName } from './dependencies'
import { TelephoneNumber } from '@lifedot/components/TelephoneNumber'
import { palette } from '@lifedot/styles/palette'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { List, ListItem } from '@lifedot/atoms/List'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { router } from '@lifedot/router'

const styles = {
  box: css({
    backgroundColor: palette.background,
    padding: '20px 40px',
    [mq('sp')]: {
      padding: 12
    }
  }),
  heading: css({
    marginBottom: 12
  }),
  content: css({
    display: 'grid',
    gap: 8,
    li: typography.textM
  }),
  listIcon: css({
    color: palette.main,
    fontSize: '14px !important'
  }),
  tel: css({
    backgroundColor: palette.white,
    padding: 12,
    width: '100%',
    margin: '0 auto'
  }),
  telTitle: css({
    textAlign: 'center',
    fontWeight: 'bold'
  }),
  link: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.main03
  })
}

export const Hakajimai: FC = () => {
  const areaName = useAreaName()

  return (
    <div css={styles.box}>
      <h2 css={[styles.heading, typography.textXL]}>
        墓じまいと永代供養のお墓探しは、まとめて検討！
      </h2>
      <div css={styles.content}>
        <p css={typography.textM}>
          墓じまい代行から{areaName}
          でのお墓探し、供養方法の相談までトータルサポートいたします。
        </p>
        <List icon={<FiberManualRecordIcon css={styles.listIcon} />}>
          <ListItem>{areaName}おすすめのお墓をご案内</ListItem>
          <ListItem>今お持ちのお墓の撤去費用の見積り</ListItem>
          <ListItem>墓じまいに必要な行政手続きの代行</ListItem>
        </List>
        <div css={styles.tel}>
          <p css={[typography.textS, styles.telTitle]}>
            墓じまいに関するご相談はこちら
          </p>
          <TelephoneNumber showNote />
        </div>
        <a
          href={router.hakajimai()}
          target="_blank"
          rel="noopener noreferrer nofollow"
          css={[typography.textS, styles.link]}
        >
          ライフドットの墓じまいについて
          <ChevronRightIcon fontSize="small" />
        </a>
      </div>
    </div>
  )
}
