import { listPath, router } from '@lifedot/router'
import { useRouter } from 'next/router'
import { useListPage } from '@/hooks/use-list-page'

export type LinkData = Array<{
  href: string
  name: string
}>

export const useBreadcrumbItemData = (): LinkData => {
  const {
    prefecture,
    city,
    station,
    condition,
    pagination: { currentPageNumber }
  } = useListPage()
  const { asPath } = useRouter()

  return [
    { href: '/', name: '【お墓・霊園・墓地探し】ライフドット' },
    city || station || condition
      ? {
          href: listPath({ prefectureRoma: prefecture.roma }),
          name: `${prefecture.name}のおすすめお墓一覧ランキング（霊園・墓地）`
        }
      : null,
    !city && !station && !condition
      ? {
          href: router.list({ prefectureRoma: prefecture.roma }),
          name: `${prefecture.name}のお墓（霊園・墓地）`
        }
      : null,
    city && city.designatedCity
      ? {
          href: router.list({
            prefectureRoma: prefecture.roma,
            cityId: city.designatedCity.designatedCityId
          }),
          name: `${city.designatedCity.name}のお墓（霊園・墓地）`
        }
      : null,
    city
      ? {
          href: router.list({
            prefectureRoma: prefecture.roma,
            cityId: city?.cityId
          }),
          name: `${city?.name}のお墓（霊園・墓地）`
        }
      : null,
    station?.designatedCityName &&
    station.designatedCityId &&
    station.cityCemeteryCount > 0
      ? {
          href: router.list({
            prefectureRoma: station.prefectureRoma,
            cityId: station.designatedCityId
          }),
          name: `${station.designatedCityName}のお墓（霊園・墓地）`
        }
      : null,
    station && station.cityCemeteryCount > 0
      ? {
          href: router.list({
            prefectureRoma: station.prefectureRoma,
            cityId: station.cityId
          }),
          name: `${station.cityName}のお墓（霊園・墓地）`
        }
      : null,
    station
      ? {
          href: router.list({
            prefectureRoma: station.prefectureRoma,
            stationId: station.stationId
          }),
          name: `${station.name}駅のお墓（霊園・墓地）`
        }
      : null,
    condition
      ? {
          href: router.list({
            prefectureRoma: prefecture.roma,
            cityId: city?.cityId,
            stationId: station?.stationId,
            condition
          }),
          name: condition.name
        }
      : null,
    currentPageNumber && asPath && currentPageNumber > 1
      ? { href: asPath, name: `${currentPageNumber}ページ目` }
      : null
  ].filter((item): item is Exclude<typeof item, null> => item !== null)
}
