/**
 * @generated SignedSource<<9e66b9bef982d794a6a37254f2920141>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type HighScoreCemeteries_highScoreCemeteries$data = {
  readonly items: ReadonlyArray<{
    readonly cemeteryId: number
    readonly name: string
    readonly review_summary: {
      readonly count: number
      readonly total_score: number | null
    }
  }>
  readonly ' $fragmentType': 'HighScoreCemeteries_highScoreCemeteries'
}
export type HighScoreCemeteries_highScoreCemeteries$key = {
  readonly ' $data'?: HighScoreCemeteries_highScoreCemeteries$data
  readonly ' $fragmentSpreads': FragmentRefs<'HighScoreCemeteries_highScoreCemeteries'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'HighScoreCemeteries_highScoreCemeteries',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'Cemetery',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: 'cemeteryId',
          args: null,
          kind: 'ScalarField',
          name: 'id',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          concreteType: 'CemeteryReviewSummary',
          kind: 'LinkedField',
          name: 'review_summary',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'count',
              storageKey: null
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'total_score',
              storageKey: null
            }
          ],
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CemeteryResult',
  abstractKey: null
}

;(node as any).hash = '6270adeeac941afaf2fe68e2c8175fc6'

export default node
