import { css } from '@emotion/react'
import { FC } from 'react'
import { Label } from '@lifedot/atoms/Label'

const styles = css({
  '> :not(:last-of-type)': {
    marginRight: 4
  }
})

interface Props {
  labels: { name: string }[]
}

export const Labels: FC<Props> = ({ labels }) => {
  return (
    <p css={styles}>
      {labels.map(({ name }) => (
        <Label key={name} color="gray6">
          {name}
        </Label>
      ))}
    </p>
  )
}
