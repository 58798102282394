import { useListPage } from '@/hooks/use-list-page'

export const useTitle = (year: string): string => {
  const { prefecture, city, station, condition } = useListPage()
  const conditionText = condition ? `「${condition.name}」` : ''
  const prefix = `【${year}年版】`

  if (station)
    return `${prefix}${station.name}駅周辺${conditionText}お墓平均費用`
  if (city) return `${prefix}${city.name}${conditionText}お墓平均費用`
  return `${prefix}${prefecture.name}${conditionText}お墓平均費用`
}
