import { FC, ReactNode, useState } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { typography } from '@lifedot/styles/typography'

const styles = {
  wrapper: (height: SeeMoreProps['visibleHeight']) =>
    css({
      height: height ?? 300,
      overflow: 'hidden',
      position: 'relative'
    }),
  open: css({
    height: 'auto'
  }),
  button: (isSiteBaseBackground: SeeMoreProps['isSiteBaseBackground']) =>
    css({
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      width: '100%',
      padding: '12px 0',
      position: 'absolute',
      bottom: 0,
      background: isSiteBaseBackground
        ? `linear-gradient(0deg,${palette.gray1} 50%, transparent);`
        : `linear-gradient(0deg, ${palette.white} 50%, transparent)`,
      height: 80,
      cursor: 'pointer'
    }),
  text: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.main03
  })
}

interface SeeMoreProps {
  children: ReactNode
  label?: string
  visibleHeight?: number
  defaultFold?: boolean
  isSiteBaseBackground?: boolean
}

export const SeeMore: FC<SeeMoreProps> = ({
  label,
  visibleHeight,
  defaultFold = true,
  children,
  isSiteBaseBackground
}) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!defaultFold) return <>{children}</>

  return (
    <div css={open ? styles.open : styles.wrapper(visibleHeight)}>
      {children}
      {!open && (
        <div
          css={[typography.textM, styles.button(isSiteBaseBackground)]}
          onClick={handleOpen}
        >
          <button css={styles.text}>
            {label ?? 'もっと見る'}
            <ExpandMoreIcon />
          </button>
        </div>
      )}
    </div>
  )
}
