import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { useListPage } from '@/hooks/use-list-page'
import { noticeForPrice } from '@lifedot/constants/noticeForPrice'

const styles = {
  notes: css({
    color: palette.gray6,
    display: 'grid',
    gap: 8,
    '> li::before': {
      content: '"※"'
    },
    [mq('sp')]: {
      margin: '0 12px'
    }
  })
}

export const Notice: FC = () => {
  const {
    searchParams: { sort }
  } = useListPage()

  return (
    <ul css={[typography.textS, styles.notes]}>
      <li>{noticeForPrice}</li>
      {sort.field === 'ranking' && (
        <li>
          この人気ランキングは、当サイトに掲載する該当エリアでよく見られている霊園順です。
        </li>
      )}
    </ul>
  )
}
