import { priceFormatter } from '@lifedot/utils'
import { useAveragePrices_averages$data } from './__generated__/useAveragePrices_averages.graphql'

export type Item = {
  normal?: string
  jumoku?: string
  nokotsu?: string
  eitai_kuyo?: string
}

export const makeItems = (prices: useAveragePrices_averages$data): Item =>
  prices.averages.reduce<Item>(
    (current, item) => {
      if (item.price === null || item.price <= 0) return current
      return {
        ...current,
        [item.category_code]: `${
          priceFormatter(item.price, { decimalPoints: 0 }) as string
        }円`
      }
    },
    {
      normal: undefined,
      jumoku: undefined,
      nokotsu: undefined,
      eitai_kuyo: undefined
    }
  )
