import { FC, AnchorHTMLAttributes } from 'react'
import { css } from '@emotion/react'
import { Rating } from '@lifedot/atoms/Rating'
import { Card } from '@lifedot/atoms/Card'
import { typography } from '@lifedot/styles/typography'
import { router } from '@lifedot/router'
import Image from 'next/image'
import { Labels } from './Labels'
import { PurchasePrice } from './PurchasePrice'
import { Description } from './Description'
import { useCemeteryTinyItem } from '@lifedot/hooks/use-cemetery-tiny-item'
import { useCemeteryTinyItem_cemetery$key } from '@lifedot/hooks/use-cemetery-tiny-item/__generated__/useCemeteryTinyItem_cemetery.graphql'

const styles = {
  root: css({
    display: 'block',
    textDecoration: 'none',
    height: '100%',
    '> *': {
      height: '100%'
    }
  }),
  inner: css({
    display: 'flex'
  }),
  content: css({
    width: '100%',
    padding: 12
  }),
  imgDefault: css({
    position: 'relative',
    width: '100%',
    height: 130
  }),
  imgHorizontal: css({
    position: 'relative',
    width: '35%',
    height: 172,
    flexShrink: 0
  })
}

export interface CemeteryCardProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  cemetery: useCemeteryTinyItem_cemetery$key
  directorComment?: boolean
  review?: boolean
  horizontal?: boolean
}

export const CemeteryCard: FC<CemeteryCardProps> = ({
  cemetery,
  directorComment: directorCommentFlag = false,
  review: reviewFlag = true,
  horizontal = false,
  ...props
}) => {
  const {
    cemeteryId,
    image,
    cemeteryName,
    cityName,
    prefectureName,
    review,
    directorComment,
    labels,
    price
  } = useCemeteryTinyItem(cemetery)

  return (
    <a href={router.cemetery(cemeteryId)} css={styles.root} {...props}>
      <Card shadow round={1} outlineWidth={1}>
        <div css={horizontal && styles.inner}>
          <div css={horizontal ? styles.imgHorizontal : styles.imgDefault}>
            <Image
              src={image.path}
              layout="fill"
              objectFit="cover"
              alt={image.alt ?? ''}
            />
          </div>
          <div css={styles.content}>
            <h3 css={typography.textL}>{cemeteryName}</h3>
            <p css={typography.textS}>
              {prefectureName} / {cityName}
            </p>
            {reviewFlag && review?.score ? <Rating point={review.score} /> : ''}
            <Labels labels={labels} />
            <PurchasePrice price={price} />
            {directorCommentFlag && <Description text={directorComment} />}
          </div>
        </div>
      </Card>
    </a>
  )
}
