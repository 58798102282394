import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { transition } from '@lifedot/styles/transition'
import { zIndex } from '@lifedot/styles/zIndex'

const styles = {
  root: (place: 'top' | 'bottom') =>
    css({
      position: 'fixed',
      left: place === 'top' ? 'auto' : 0,
      top: place === 'top' ? 0 : 'auto',
      bottom: place === 'bottom' ? 0 : 'auto',
      transform: place === 'top' ? 'translateY(-120%)' : 'translateY(120%)',
      width: '100%',
      transition: transition('easeOut'),
      zIndex: zIndex.header
    }),
  visible: css({
    transform: 'translateY(0)'
  })
}

interface StickyWrapperProps {
  offset?: number
  children: ReactNode
  place: 'top' | 'bottom'
}

export const StickyWrapper: FC<StickyWrapperProps> = ({
  offset = 0,
  children,
  place
}) => {
  const [isEnd, setIsEnd] = useState(false)

  const position = useCallback(() => {
    const y = Math.max(
      window.scrollY,
      document.documentElement.scrollTop,
      document.body.scrollTop
    )
    setIsEnd(y > offset)
  }, [offset])

  useEffect(() => {
    document.addEventListener('scroll', position)
    return () => document.removeEventListener('scroll', position)
  }, [position])

  return (
    <div css={[styles.root(place), isEnd && styles.visible]}>
      {isEnd && children}
    </div>
  )
}
