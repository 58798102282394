import { FC, ReactNode, useCallback, useState } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'

const styles = {
  root: css({
    background: palette.white,
    padding: '12px 40px',
    [mq('sp')]: {
      padding: 12
    }
  }),
  body: css({
    height: 110,
    overflow: 'hidden'
  }),
  isOpen: css({
    height: 'auto'
  }),
  accordion: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    color: palette.main03,
    textDecoration: 'underline',
    marginTop: 8
  })
}

export interface PartialDisplayAccordionProps {
  children: ReactNode
  accordionTitle: string
}

export const PartialDisplayAccordion: FC<PartialDisplayAccordionProps> = ({
  accordionTitle,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen])

  return (
    <section css={styles.root}>
      <div css={[styles.body, isOpen && styles.isOpen]}>{children}</div>
      <button onClick={handleClick} css={[typography.textS, styles.accordion]}>
        {isOpen ? '閉じる' : accordionTitle}
        {isOpen ? (
          <IndeterminateCheckBoxOutlinedIcon fontSize="small" />
        ) : (
          <AddBoxOutlinedIcon fontSize="small" />
        )}
      </button>
    </section>
  )
}
