/**
 * @generated SignedSource<<3477c16315723a0af058b6a7e21f1032>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type useCemeteryTinyItem_cemetery$data = {
  readonly cemeteryId: number
  readonly name: string
  readonly prefecture: {
    readonly name: string
  }
  readonly city: {
    readonly name: string
  }
  readonly review_summary: {
    readonly total_score: number | null
    readonly count: number
  }
  readonly director_comment: string | null
  readonly main_image: {
    readonly alt: string | null
    readonly path: string | null
  } | null
  readonly section_types: ReadonlyArray<{
    readonly name: string
  }>
  readonly cemetery_types: ReadonlyArray<{
    readonly name: string
    readonly code: string
  }>
  readonly sects: ReadonlyArray<{
    readonly name: string
  }>
  readonly best_price_by_category: {
    readonly normal: {
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly category_code: string
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly simulated_price3: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly lot_area: number | null
    } | null
    readonly nokotsu: {
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly category_code: string
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly simulated_price3: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly lot_area: number | null
    } | null
    readonly jumoku: {
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly category_code: string
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly simulated_price3: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly lot_area: number | null
    } | null
    readonly eitai_kuyo: {
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly category_code: string
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly simulated_price3: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly lot_area: number | null
    } | null
  }
  readonly ' $fragmentType': 'useCemeteryTinyItem_cemetery'
}
export type useCemeteryTinyItem_cemetery$key = {
  readonly ' $data'?: useCemeteryTinyItem_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'useCemeteryTinyItem_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v1 = [v0 /*: any*/],
    v2 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price1',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price1_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price2',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price2_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'calculated_purchase_price',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'category_code',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price_option',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price3',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'simulated_price3',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price4',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price4_name',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lot_area',
        storageKey: null
      }
    ]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'useCemeteryTinyItem_cemetery',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      v0 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryPrefecture',
        kind: 'LinkedField',
        name: 'prefecture',
        plural: false,
        selections: v1 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryCity',
        kind: 'LinkedField',
        name: 'city',
        plural: false,
        selections: v1 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryReviewSummary',
        kind: 'LinkedField',
        name: 'review_summary',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'total_score',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'count',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'director_comment',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryImage',
        kind: 'LinkedField',
        name: 'main_image',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'alt',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'path',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'section_types',
        plural: true,
        selections: v1 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'cemetery_types',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'code',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'sects',
        plural: true,
        selections: v1 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryBestPriceByCategory',
        kind: 'LinkedField',
        name: 'best_price_by_category',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'normal',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'nokotsu',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'jumoku',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'eitai_kuyo',
            plural: false,
            selections: v2 /*: any*/,
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'CemeteryBase',
    abstractKey: '__isCemeteryBase'
  }
})()

;(node as any).hash = 'b98a5135694ffbe53d569e8187a1406b'

export default node
