import { FC } from 'react'
import { PartialDisplayAccordion } from '@lifedot/components/PartialDisplayAccordion'
import { GridLinks } from '@lifedot/components/GridLinks'
import { typography } from '@lifedot/styles/typography'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { useListPage } from '@/hooks/use-list-page'
import { ShopLinksList_Fragment$key } from './__generated__/ShopLinksList_Fragment.graphql'
import { router } from '@lifedot/router'

interface CityLinksProps {
  fragmentRef: ShopLinksList_Fragment$key
}

export const ShopLinksList: FC<CityLinksProps> = ({ fragmentRef }) => {
  const data = useFragment(fragment, fragmentRef)
  const { prefecture } = useListPage()
  if (!data || !data.items.length) return null
  const items = data.items
    .filter(({ shopCount }) => shopCount > 0)
    .map(({ name, cityId }) => ({
      name: name,
      url: router.shopList({ prefectureRoma: prefecture.roma, cityId })
    }))
  if (!data.items.length) return null
  return (
    <PartialDisplayAccordion
      accordionTitle={`${prefecture.name}の市区町村を全て表示`}
    >
      <h3 css={typography.textM}>
        {prefecture.name}内の石材店･墓石販売店を探す
      </h3>
      <GridLinks items={items} />
    </PartialDisplayAccordion>
  )
}

const fragment = graphql`
  fragment ShopLinksList_Fragment on CityWithShopCountConnection {
    items {
      prefectureRoma
      name
      cityId: id
      shopCount
    }
  }
`
