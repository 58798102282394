import { FC, ReactNode } from 'react'
import { css } from '@emotion/react'
import Image from 'next/image'
import { getRankImagePath } from './dependencies'
import { palette } from '@lifedot/styles/palette'
import { rgba } from 'emotion-rgba'

const styles = {
  root: css({
    position: 'relative'
  }),
  icon: css({
    position: 'absolute',
    top: -4,
    left: 8,
    filter: `drop-shadow(2px 2px 2px ${rgba(palette.black, 0.6)})`
  })
}

export interface RankingWrapperProps {
  children: ReactNode
  rank: number
}

export const RankingWrapper: FC<RankingWrapperProps> = ({ rank, children }) => (
  <section css={styles.root}>
    {children}
    <div css={styles.icon}>
      <Image
        src={getRankImagePath(rank)}
        width={40}
        height={60}
        alt={`人気ランキング${rank}位`}
      />
    </div>
  </section>
)
