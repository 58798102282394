import { FC, ReactNode } from 'react'
import { SeoHead } from '@/components/Seo/Default'
import { Breadcrumb } from '@/components/Breadcrumb'
import { MapDynamic } from '@/components/Map'
import { SingleColumn } from '@lifedot/layout'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { CemeteryList } from '@/components/CemeteryList'
import { Banners } from '@/components/Banners/Banners'
import { Ranking } from '@/components/Ranking'
import { StockListFooterRequestButtonDynamic } from '@/components/StockListFooterRequestButton'
import { SearchButtonsWithPanelTinyDynamic } from '@/components/SearchButtonsWithPanelTiny'
import { PaginationStyled } from '@/components/Pagination'
import { PageTitleStyled } from '@/components/PageTitle'
import { PageDescription } from '@/components/PageDescription'
import { PromotionBanner } from '@/components/PromotionBanner'
import { StickyWrapper } from '@lifedot/components/StickyWrapper'
import { StructuredData } from '@/components/StructuredData'
import { ThirdPartyAdData } from '@/components/ThirdPartyAdData'
import { NoCemeteries } from '@/components/ErrorHandler'
import { SearchButtonsWithPanel } from '@/components/SearchButtonsWithPanel'
import { ListOrMapSelectTabs } from '@/components/ListOrMapSelectTabs'
import { SearchResultStatus } from '@/components/SearchResultStatus'
import { prefectureListPage_indexQuery$data } from '@/queries/__generated__/prefectureListPage_indexQuery.graphql'
import { prefectureCityListPage_indexQuery$data } from '@/queries/__generated__/prefectureCityListPage_indexQuery.graphql'
import { prefectureConditionListPage_indexQuery$data } from '@/queries/__generated__/prefectureConditionListPage_indexQuery.graphql'
import { prefectureCityConditionListPage_indexQuery$data } from '@/queries/__generated__/prefectureCityConditionListPage_indexQuery.graphql'
import { prefectureStationListPage_indexQuery$data } from '@/queries/__generated__/prefectureStationListPage_indexQuery.graphql'
import { prefectureStationConditionListPage_indexQuery$data } from '@/queries/__generated__/prefectureStationConditionListPage_indexQuery.graphql'
import { Telephone } from '@/components/Telephone'
import { Notice } from '@/components/Notice'
import { useListPage } from '@/hooks/use-list-page'
import { useGa4ViewTracking } from '@lifedot/tracking'
import { elementCategories } from '@lifedot/constants/elementCategories'
import { SiteDescription } from '@/components/SiteDescription'
import { CampaignBanner } from '@lifedot/components/CampaignBanner'

interface CommonContentsProps {
  children: ReactNode
  data:
    | prefectureListPage_indexQuery$data
    | prefectureCityListPage_indexQuery$data
    | prefectureConditionListPage_indexQuery$data
    | prefectureCityConditionListPage_indexQuery$data
    | prefectureStationListPage_indexQuery$data
    | prefectureStationConditionListPage_indexQuery$data
  prefecture: {
    roma: string
    name: string
  }
}

export const CommonContents: FC<CommonContentsProps> = ({
  data,
  prefecture,
  children
}) => {
  const { total, city } = useListPage()
  useGa4ViewTracking({
    elementCategory: elementCategories.resultCemeteryCard,
    elementLabel: `${total}`
  })
  useGa4ViewTracking({
    elementCategory: elementCategories.resultCityname,
    elementLabel: city?.name
  })

  return (
    <>
      <SeoHead {...data} />
      <AddMarginWrapper spacing={10}>
        <SingleColumn>
          <Breadcrumb />
          <AddMarginWrapper spacing={5}>
            <PageTitleStyled />
            <AddMarginWrapper spacing={2}>
              <PageDescription {...data} />
              <SearchButtonsWithPanel {...data} />
            </AddMarginWrapper>
            <AddMarginWrapper spacing={6}>
              <ListOrMapSelectTabs
                listContent={
                  <AddMarginWrapper spacing={8}>
                    <SearchResultStatus />
                    <AddMarginWrapper spacing={7}>
                      <AddMarginWrapper spacing={5}>
                        {'banners' in data && (
                          <PromotionBanner {...data} priority />
                        )}
                        <CampaignBanner />
                      </AddMarginWrapper>
                    </AddMarginWrapper>
                    <AddMarginWrapper spacing={6}>
                      <CemeteryList {...data} />
                      <PaginationStyled />
                      <Notice />
                      <Telephone />
                    </AddMarginWrapper>
                  </AddMarginWrapper>
                }
                mapContent={<MapDynamic />}
              />
              <Ranking {...data} />
              {children}
              <Banners />
            </AddMarginWrapper>
          </AddMarginWrapper>
        </SingleColumn>
        <SiteDescription />
      </AddMarginWrapper>
      <StockListFooterRequestButtonDynamic />
      <StickyWrapper offset={400} place="top">
        <SearchButtonsWithPanelTinyDynamic />
      </StickyWrapper>
      <StructuredData {...data} prefectureName={prefecture.name} />
      <ThirdPartyAdData {...data} />
      <NoCemeteries {...data} />
    </>
  )
}
