import { FC } from 'react'
import { css } from '@emotion/react'
import { Card, CardContent } from '@lifedot/atoms/Card'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { useTitle } from './dependencies'
import dayjs from 'dayjs'
import { useAveragePrices } from '@lifedot/hooks/use-average-prices'
import { useAveragePrices_averages$key } from '@lifedot/hooks/use-average-prices/__generated__/useAveragePrices_averages.graphql'

const styles = {
  table: css({
    width: '100%',
    tbody: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      [mq('sp')]: {
        justifyContent: 'flex-start'
      }
    },
    tr: {
      [mq('sp')]: {
        width: '50%',
        wordBreak: 'keep-all'
      }
    }
  }),
  heading: css({
    marginBottom: 8,
    [mq('sp')]: {
      padding: '0 12px'
    }
  }),
  marker: css({
    fontWeight: 'bold',
    backgroundColor: palette.marker,
    padding: 2
  }),
  caption: css({
    color: palette.gray6,
    marginLeft: 4
  })
}

interface AveragePrice {
  averages: useAveragePrices_averages$key
}

export const AveragePrice: FC<AveragePrice> = ({ averages }) => {
  const { normal, eitai_kuyo, nokotsu, jumoku } = useAveragePrices(averages)
  const title = useTitle(dayjs().format('YYYY'))

  if (!normal && !jumoku && !nokotsu && !eitai_kuyo) return null

  return (
    <>
      <h2 css={[styles.heading, typography.headingXS]} id="averagePrice">
        {title}
      </h2>
      <Card>
        <CardContent spacing={3}>
          <table css={styles.table}>
            <tbody>
              {normal && (
                <tr>
                  <th css={typography.textS}>一般墓：</th>
                  <td>
                    <span css={[typography.textL, styles.marker]}>
                      {normal}
                    </span>
                    <span css={[typography.textXS, styles.caption]}>
                      ※墓石代別
                    </span>
                  </td>
                </tr>
              )}
              {jumoku && (
                <tr>
                  <th css={typography.textS}>樹木葬：</th>
                  <td>
                    <span css={[typography.textL, styles.marker]}>
                      {jumoku}
                    </span>
                  </td>
                </tr>
              )}
              {nokotsu && (
                <tr>
                  <th css={typography.textS}>納骨堂：</th>
                  <td>
                    <span css={[typography.textL, styles.marker]}>
                      {nokotsu}
                    </span>
                  </td>
                </tr>
              )}
              {eitai_kuyo && (
                <tr>
                  <th css={typography.textS}>永代供養墓：</th>
                  <td>
                    <span css={[typography.textL, styles.marker]}>
                      {eitai_kuyo}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardContent>
      </Card>
    </>
  )
}
