/**
 * @generated SignedSource<<ca19f2353fec92161c80734ebb640fec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ThirdPartyAdData_cemeteries$data = {
  readonly items: ReadonlyArray<{
    readonly cemeteryId: number
    readonly code: string
    readonly best_price_by_category: {
      readonly normal: {
        readonly price1: number | null
        readonly price1_unit: string | null
        readonly price2: number | null
        readonly purchase_price: number | null
        readonly purchase_price_unit: string | null
        readonly calculated_purchase_price: number | null
        readonly category_code: string
        readonly purchase_price_option: string
        readonly price3: number | null
        readonly simulated_price3: number | null
        readonly price4: number | null
        readonly price4_name: string | null
      } | null
      readonly nokotsu: {
        readonly price1: number | null
        readonly price1_unit: string | null
        readonly price2: number | null
        readonly purchase_price: number | null
        readonly purchase_price_unit: string | null
        readonly calculated_purchase_price: number | null
        readonly category_code: string
        readonly purchase_price_option: string
        readonly price3: number | null
        readonly simulated_price3: number | null
        readonly price4: number | null
        readonly price4_name: string | null
      } | null
      readonly jumoku: {
        readonly price1: number | null
        readonly price1_unit: string | null
        readonly price2: number | null
        readonly purchase_price: number | null
        readonly purchase_price_unit: string | null
        readonly calculated_purchase_price: number | null
        readonly category_code: string
        readonly purchase_price_option: string
        readonly price3: number | null
        readonly simulated_price3: number | null
        readonly price4: number | null
        readonly price4_name: string | null
      } | null
      readonly eitai_kuyo: {
        readonly price1: number | null
        readonly price1_unit: string | null
        readonly price2: number | null
        readonly purchase_price: number | null
        readonly purchase_price_unit: string | null
        readonly calculated_purchase_price: number | null
        readonly category_code: string
        readonly purchase_price_option: string
        readonly price3: number | null
        readonly simulated_price3: number | null
        readonly price4: number | null
        readonly price4_name: string | null
      } | null
    }
  }>
  readonly ' $fragmentType': 'ThirdPartyAdData_cemeteries'
}
export type ThirdPartyAdData_cemeteries$key = {
  readonly ' $data'?: ThirdPartyAdData_cemeteries$data
  readonly ' $fragmentSpreads': FragmentRefs<'ThirdPartyAdData_cemeteries'>
}

const node: ReaderFragment = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price1',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price1_unit',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price2',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'purchase_price',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'purchase_price_unit',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'calculated_purchase_price',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'category_code',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'purchase_price_option',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price3',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'simulated_price3',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price4',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price4_name',
      storageKey: null
    }
  ]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'ThirdPartyAdData_cemeteries',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'Cemetery',
        kind: 'LinkedField',
        name: 'items',
        plural: true,
        selections: [
          {
            alias: 'cemeteryId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'code',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryBestPriceByCategory',
            kind: 'LinkedField',
            name: 'best_price_by_category',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'normal',
                plural: false,
                selections: v0 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'nokotsu',
                plural: false,
                selections: v0 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'jumoku',
                plural: false,
                selections: v0 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'eitai_kuyo',
                plural: false,
                selections: v0 /*: any*/,
                storageKey: null
              }
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'CemeteryResult',
    abstractKey: null
  }
})()

;(node as any).hash = '6bdb7cfa532a285e0fd55f5243faaa1f'

export default node
