import dynamic from 'next/dynamic'
import { FC } from 'react'

export const SearchButtonsWithPanelTinyDynamic: FC = () => {
  const SearchButtonsWithPanelTiny = dynamic<Record<string, never>>(() =>
    import('./SearchButtonsWithPanelTiny').then(
      (mod) => mod.SearchButtonsWithPanelTiny
    )
  )
  return <SearchButtonsWithPanelTiny />
}
