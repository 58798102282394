import { FC, LiHTMLAttributes, ReactNode } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'

const styles = {
  item: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 50,
    height: 50,
    border: `1px solid ${palette.gray3}`,
    background: palette.white,
    marginRight: 12,
    [mq('sp')]: {
      width: 30,
      height: 30,
      marginRight: 4
    }
  }),
  current: css({
    background: palette.gray6,
    color: palette.white,
    border: `1px solid ${palette.gray6}`
  }),
  anchor: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    textDecoration: 'none',
    '&:hover': {
      background: palette.gray1
    }
  })
}

export interface PaginationItemProps extends LiHTMLAttributes<HTMLLIElement> {
  index: ReactNode
  isCurrent?: boolean
  href?: string
  prev?: boolean
  next?: boolean
}

export const PaginationItem: FC<PaginationItemProps> = (props) => {
  const { index, isCurrent, href, prev, next, ...rest } = props
  if (isCurrent)
    return (
      <li css={[styles.item, styles.current]} {...rest}>
        {index}
      </li>
    )
  if (href)
    return (
      <li css={styles.item} {...rest}>
        <a
          href={href}
          css={styles.anchor}
          rel={prev ? 'prev' : next ? 'next' : undefined}
          aria-label={
            prev ? '前のページへ移動' : next ? '次のページへ移動' : undefined
          }
        >
          {index}
        </a>
      </li>
    )
  return (
    <li css={styles.item} {...rest}>
      {index}
    </li>
  )
}
