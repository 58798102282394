import { FC } from 'react'
import { css } from '@emotion/react'
import { List, ListItem } from '@lifedot/atoms/List'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { SortFields, defaultSort, sortWithNames } from '@lifedot/constants/sort'
import { router } from '@lifedot/router'
import { EventTracker } from '@lifedot/tracking'
import { useListPage } from '@/hooks/use-list-page'
import { elementCategories } from '@lifedot/constants/elementCategories'

const styles = {
  sort: css({
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    li: {
      ':not(:first-of-type)': {
        marginLeft: 8
      }
    }
  }),
  link: css({
    color: palette.main03,
    textDecoration: 'none'
  }),
  selected: css({
    color: palette.black,
    fontWeight: 'bold',
    borderBottom: `3px solid ${palette.gray3}`
  }),
  unselected: css({
    fontWeight: 'normal'
  })
}

const sortKeys: SortFields[] = ['default', 'ranking', 'price']

export const SortList: FC = () => {
  const {
    prefecture: { roma: prefectureRoma },
    searchParams: { cityIds, stationIds, conditions, sort }
  } = useListPage()

  return (
    <div css={styles.sort}>
      <List orientation="horizontal">
        {sortKeys.map((sortKey) => {
          const sortField = sortWithNames[sortKey]?.field
          const sortName = sortWithNames[sortKey]?.name
          return (
            <ListItem key={sortKey}>
              {sort['field'] === sortField ? (
                <h3 css={[typography.textM, styles.selected]}>{sortName}</h3>
              ) : (
                <EventTracker
                  label={`sort_${sortName}`}
                  action="click"
                  elementCategory={elementCategories.selectSort}
                  elementLabel={sortName}
                >
                  <a
                    href={router.list({
                      prefectureRoma: prefectureRoma,
                      cityId: cityIds,
                      stationId: stationIds,
                      condition: conditions,
                      order: sortField
                    })}
                    rel={
                      sortField === defaultSort.field ? undefined : 'nofollow'
                    }
                    css={[typography.textM, styles.link]}
                  >
                    <h3 css={styles.unselected}>{sortName}</h3>
                  </a>
                </EventTracker>
              )}
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}
