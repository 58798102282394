import { FC, useCallback } from 'react'
import {
  Pagination as PaginationMikage,
  PaginationItem
} from '@lifedot/atoms/Pagination'
import { router } from '@lifedot/router'
import { fill } from '@lifedot/utils'
import { useListPage } from '@/hooks/use-list-page'

export const Pagination: FC = () => {
  const {
    prefecture: { roma: prefectureRoma },
    searchParams: { cityIds, stationIds, conditions, sort },
    pagination
  } = useListPage()
  const makeHref = useCallback(
    (page: number) =>
      router.list({
        prefectureRoma: prefectureRoma,
        cityId: cityIds,
        stationId: stationIds,
        condition: conditions,
        order: sort.field,
        page: page
      }),
    [cityIds, conditions, prefectureRoma, sort.field, stationIds]
  )
  const totalPages = fill(1, pagination.lastPageNumber)

  return (
    <PaginationMikage current={pagination.currentPageNumber}>
      {totalPages.map((page) => (
        <PaginationItem index={page} href={makeHref(page)} key={page} />
      ))}
    </PaginationMikage>
  )
}
