import { FC } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { NoCemeteries_cemeteries$key } from './__generated__/NoCemeteries_cemeteries.graphql'
import { useNoCemeteriesParamsReplace } from './dependencies'

// 霊園がなければ、クエリストリングを取り払ってpathオンリーなページへ強制転送
export const NoCemeteries: FC<{ cemeteries: NoCemeteries_cemeteries$key }> = ({
  cemeteries
}) => {
  const data = useFragment(
    graphql`
      fragment NoCemeteries_cemeteries on CemeteryResult {
        total
      }
    `,
    cemeteries
  )
  useNoCemeteriesParamsReplace(data)

  return null
}
