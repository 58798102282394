import { FC } from 'react'
import { StockListFooterRequestButtonProps } from './StockListFooterRequestButton'
import dynamic from 'next/dynamic'
import { useBookmark } from '@lifedot/hooks/use-bookmark'

export const StockListFooterRequestButtonDynamic: FC = () => {
  const [bookmark] = useBookmark()
  if (!bookmark.length) return null

  const StockListFooterRequestButton =
    dynamic<StockListFooterRequestButtonProps>(() =>
      import('./StockListFooterRequestButton').then(
        (mod) => mod.StockListFooterRequestButton
      )
    )

  return <StockListFooterRequestButton />
}
