import { FC } from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { urlify } from '@lifedot/router'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { SeoHead_cemeteries$key } from './__generated__/SeoHead_cemeteries.graphql'
import {
  useDescription,
  useKeyWords,
  useNoIndexNoFollow,
  useTitle,
  useCanonical,
  makeMetaImage
} from './dependencies'

interface SeoHeadProps {
  cemeteries: SeoHead_cemeteries$key
}

export const SeoHead: FC<SeoHeadProps> = ({ cemeteries }) => {
  const { asPath } = useRouter()
  const title = useTitle()
  const description = useDescription()
  const keywords = useKeyWords()
  const [noIndex, noFollow] = useNoIndexNoFollow()
  const canonical = useCanonical()
  const { items } = useFragment(fragment, cemeteries)
  const image = makeMetaImage(items)

  return (
    <NextSeo
      title={title}
      description={description}
      additionalMetaTags={[
        { name: 'keywords', content: keywords.join(',') },
        { name: 'thumbnail', content: image.path }
      ]}
      canonical={canonical}
      noindex={noIndex}
      nofollow={noFollow}
      openGraph={{
        url: urlify(asPath),
        description,
        title,
        images: [{ url: image.path }]
      }}
    />
  )
}

const fragment = graphql`
  fragment SeoHead_cemeteries on CemeteryResult {
    items {
      main_image {
        path
      }
    }
  }
`
