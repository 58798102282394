import {
  FC,
  Children,
  cloneElement,
  isValidElement,
  Fragment,
  ReactElement,
  useMemo,
  ReactNode
} from 'react'
import { css } from '@emotion/react'
import { BreadCrumbProps } from './BreadcrumbItem'
import { typography } from '@lifedot/styles/typography'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const styles = {
  root: css({
    padding: '4px 0'
  }),
  list: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    li: {
      display: 'inline-block',
      ':last-of-type': {
        a: {
          textDecoration: 'none',
          fontWeight: 'bold'
        }
      }
    }
  })
}

export type BreadcrumbProps = {
  children?: ReactNode
}

export const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  const { children } = props
  const items = useMemo(
    () =>
      Children.toArray(children)
        .filter<ReactElement<Pick<BreadCrumbProps, 'content'>>>(isValidElement)
        .map((child, index) => {
          return cloneElement(child, { content: index + 1 })
        }),
    [children]
  )

  return (
    <nav css={styles.root} aria-label="パンくずリスト">
      <ol
        css={[styles.list, typography.textS]}
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        {items.map((item, index) => (
          <Fragment key={index}>
            {index > 0 && <ChevronRightIcon fontSize="small" />}
            <li
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              {item}
            </li>
          </Fragment>
        ))}
      </ol>
    </nav>
  )
}
