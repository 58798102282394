import { router } from '@lifedot/router'
import { useListPage } from '@/hooks/use-list-page'
import { features } from '@lifedot/constants/searchChoices'
import { needConditionSuffix } from '@/utils/need-condition-suffix'
import { sectionTypes as sectionTypesData } from '@lifedot/constants/searchChoices'
import { useSearchConditionLabel } from '@/hooks/use-search-condition-label'
import { useAveragePrices } from '@lifedot/hooks/use-average-prices'
import { useAveragePrices_averages$key } from '@lifedot/hooks/use-average-prices/__generated__/useAveragePrices_averages.graphql'
import { HighScoreCemeteries_highScoreCemeteries$data } from './__generated__/HighScoreCemeteries_highScoreCemeteries.graphql'
import { PopularCemeteries_rankingCemeteries$data } from './__generated__/PopularCemeteries_rankingCemeteries.graphql'
import { distanceFormatter } from '@lifedot/utils'

export const useLocationNameText = () => {
  const { station, city, prefecture } = useListPage()
  if (station) return `${station.name}駅(${prefecture.name})`
  if (city) return `${city.name}(${prefecture.name})`
  return prefecture.name
}

export const useFormattedConditionLabelEndsWith = () => {
  const conditionLabel = useSearchConditionLabel()
  if (!conditionLabel) return ''
  const lastPart = conditionLabel.split('／').pop()
  const featureCode = features.find(
    (feature) => feature.name === lastPart
  )?.code
  if (!featureCode || needConditionSuffix(featureCode))
    return `${conditionLabel}の霊園・墓地の`
  return `${conditionLabel}霊園・墓地の`
}

const makeAveragePriceText = ({
  averagePrice,
  sectionTypes
}: {
  averagePrice: Record<string, string>
  sectionTypes: string[]
}) => {
  return sectionTypes
    .map((code) => {
      const name = sectionTypesData.find((item) => item.code === code)?.name
      if (!averagePrice[code] || !name) return null
      return `${name}が約${averagePrice[code]}${
        code === 'normal' ? '（墓石代別途）' : ''
      }`
    })
    .filter(Boolean)
    .join('、')
}

export const usePriceText = (averages: useAveragePrices_averages$key) => {
  const {
    searchParams: { sectionTypes, conditions },
    prefecture,
    city,
    station
  } = useListPage()
  const averagePrice = useAveragePrices(averages)
  const location = useLocationNameText()
  const shortLocation = `${station?.name ?? city?.name ?? prefecture.name}${
    station ? `駅周辺` : ''
  }`
  const conditionLabel = useFormattedConditionLabelEndsWith()
  const price = makeAveragePriceText({
    averagePrice,
    sectionTypes: sectionTypes ?? Object.keys(averagePrice)
  })
  if (!price)
    return `${location}には数多くの霊園・墓地があり、その種類や費用はさまざまです。`
  if (conditions.length > 0)
    return `${location}の${conditionLabel}相場価格は、<strong>${price}</strong>です。(ライフドット調べ)`
  return `${location}には数多くの霊園・墓地があり、その種類や費用はさまざまです。${shortLocation}のお墓の具体的な相場価格は、<strong>${price}</strong>です。(ライフドット調べ)`
}

export const makeHighScoreCemeteriesMarkup = (
  filteredData: HighScoreCemeteries_highScoreCemeteries$data['items']
) => {
  if (filteredData.length < 3) return null
  const markup = filteredData
    .map(
      ({ cemeteryId, name, review_summary }, index) =>
        `${makeToCemeteriesLink({
          index,
          cemeteryId,
          name
        })}（評価${review_summary.total_score?.toFixed(1)}点・口コミ${
          review_summary.count
        }件）`
    )
    .join('')
  return `口コミ評価の高い注目のお墓・霊園は、${markup}があります。`
}

export const makePopularCemeteriesMarkup = (
  items: PopularCemeteries_rankingCemeteries$data['items']
) => {
  if (items.length === 0) return null
  const markup = items
    .slice(0, 3)
    .map(
      ({ cemeteryId, name, nearest_station: station }, index) =>
        `${makeToCemeteriesLink({ index, cemeteryId, name })}${
          station.distance && station.name
            ? `（${station.name}駅から${distanceFormatter(station.distance)}）`
            : ''
        }`
    )
    .join('')
  return `特に人気のおすすめ霊園・墓地は、${markup}が挙げられます。`
}

const makeToCemeteriesLink = ({
  index,
  cemeteryId,
  name
}: {
  index: number
  cemeteryId: number
  name: string
}) => {
  return `${index > 0 ? '、' : ''}<a href="${router.cemetery(
    cemeteryId
  )}"><strong>${name}</strong></a>`
}
