import { FC } from 'react'
import {
  useFormattedConditionLabelEndsWith,
  useLocationNameText
} from './dependencies'

export const ClosingText: FC = () => {
  const conditionLabel = useFormattedConditionLabelEndsWith()
  const locationName = useLocationNameText()
  return (
    <p>
      {`${locationName}で${conditionLabel}お墓探しをする際は、自宅からの交通アクセスを確認しつつ、法要施設や管理事務所などの設備や管理体制、近隣での供花やお線香の入手方法などを考慮して選ぶとよいでしょう。資料請求や見学予約が無料でできますので、活用してみてください。`}
    </p>
  )
}
