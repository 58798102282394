import { deducePurchasePrice } from '@lifedot/utils'
import { ThirdPartyAdData_cemeteries$data } from '@/components/ThirdPartyAdData/__generated__/ThirdPartyAdData_cemeteries.graphql'

type AdData = {
  id: number
  code: string
  purchasePrice: {
    price: number | null
  }
}

export const makeAdCemeteryData = (
  cemeteries: ThirdPartyAdData_cemeteries$data['items']
): AdData[] => {
  return cemeteries.map<AdData>((cemetery) => {
    const price = Object.values(cemetery.best_price_by_category).reduce<
      null | number
    >((res, price) => {
      if (!price) return res
      const purchasePrice = deducePurchasePrice(price)
      if (res && res < purchasePrice) return res
      return purchasePrice
    }, null)

    return {
      id: cemetery.cemeteryId,
      code: cemetery.code,
      purchasePrice: {
        price
      }
    }
  })
}
