import { FC } from 'react'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { CemeteryList_cemeteries$key } from './__generated__/CemeteryList_cemeteries.graphql'
import { CemeteryListItem } from '@lifedot/components/CemeteryListItem'
import { convertDataToItem } from '@/components/CemeteryList/dependencies'
import { useListPage } from '@/hooks/use-list-page'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  listInfo: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [mq('sp')]: {
      display: 'block',
      textAlign: 'right',
      paddingRight: 8
    }
  }),
  balloon: css({
    position: 'relative',
    backgroundColor: palette.gray6,
    color: palette.white,
    padding: '4px 12px',
    display: 'inline-block',
    borderRadius: 4,
    marginBottom: 8,
    [mq('sp')]: {
      marginTop: 4
    },
    '::after': {
      content: '""',
      position: 'absolute',
      bottom: -10,
      right: 22,
      borderRight: '4px solid transparent',
      borderTop: `6px solid ${palette.gray6}`,
      borderLeft: '4px solid transparent',
      height: 4,
      [mq('sp')]: {
        right: 15
      }
    }
  }),
  wrap: css({
    position: 'relative'
  }),
  note: css({
    color: palette.gray6
  })
}

interface CemeteryListProps {
  cemeteries: CemeteryList_cemeteries$key
}

export const CemeteryList: FC<CemeteryListProps> = ({ cemeteries }) => {
  const { items } = useFragment(fragment, cemeteries)
  const {
    station,
    searchParams: { sectionTypes }
  } = useListPage()

  return (
    <>
      <div css={[typography.textS, styles.listInfo]}>
        <p css={styles.note}>※このページにはPRリンクが含まれています</p>
        <p css={styles.balloon}>
          チェックして検討リストに追加・まとめて資料請求
        </p>
      </div>
      <AddMarginWrapper spacing={4}>
        {items.map((cemetery) => (
          <div css={styles.wrap} key={cemetery.cemeteryId}>
            <CemeteryListItem
              {...convertDataToItem(cemetery, station?.stationId, sectionTypes)}
              imagePriority
              trackable
              moreInfo
            />
          </div>
        ))}
      </AddMarginWrapper>
    </>
  )
}

const fragment = graphql`
  fragment CemeteryList_cemeteries on CemeteryResult {
    items {
      cemeteryId: id
      name
      kana
      address
      review_summary {
        total_score
        count
        valuable_review {
          age
          gender
          answers {
            code
            text
          }
        }
      }
      descriptions {
        subject
      }
      director_comment
      stations {
        stationId: id
        distance
        name
        nearest
      }
      section_types {
        code
        name
      }
      features {
        name
      }
      cemetery_types {
        name
        code
      }
      sects {
        name
        code
      }
      main_image {
        path
        alt
      }
      sub_images {
        path
        alt
      }
      best_price_by_category {
        normal {
          price1
          price1_unit
          price2
          price2_unit
          purchase_price
          purchase_price_unit
          calculated_purchase_price
          category_code
          purchase_price_option
          price3
          simulated_price3
          price4
          price4_name
          lot_area
        }
        nokotsu {
          price1
          price1_unit
          price2
          price2_unit
          purchase_price
          purchase_price_unit
          calculated_purchase_price
          category_code
          purchase_price_option
          price3
          simulated_price3
          price4
          price4_name
          lot_area
        }
        jumoku {
          price1
          price1_unit
          price2
          price2_unit
          purchase_price
          purchase_price_unit
          calculated_purchase_price
          category_code
          purchase_price_option
          price3
          simulated_price3
          price4
          price4_name
          lot_area
        }
        eitai_kuyo {
          price1
          price1_unit
          price2
          price2_unit
          purchase_price
          purchase_price_unit
          calculated_purchase_price
          category_code
          purchase_price_option
          price3
          simulated_price3
          price4
          price4_name
          lot_area
        }
      }
      requestable
    }
  }
`
