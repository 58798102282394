import { css } from '@emotion/react'
import { DisplayablePurchasePrice } from '@lifedot/utils'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { FC } from 'react'

const styles = {
  root: css({
    textAlign: 'right',
    marginTop: 4
  }),
  number: css({
    color: palette.alert,
    fontWeight: 'bold'
  })
}

interface PurchasePriceProps {
  price: DisplayablePurchasePrice | null | undefined
}

export const PurchasePrice: FC<PurchasePriceProps> = ({ price }) => {
  return (
    <p css={[typography.textS, styles.root]}>
      参考価格:
      {price?.priceNumber ? (
        <>
          <span css={[typography.textL, styles.number]}>
            {price.priceNumber}
          </span>
          <span css={typography.textXS}>
            {price?.priceUnit}
            {price?.priceSupplement}
          </span>
        </>
      ) : (
        <span css={typography.textM}>未掲載</span>
      )}
    </p>
  )
}
