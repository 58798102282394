/**
 * @generated SignedSource<<99b711087e4416f424409adf606e7cab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type NoCemeteries_cemeteries$data = {
  readonly total: number
  readonly ' $fragmentType': 'NoCemeteries_cemeteries'
}
export type NoCemeteries_cemeteries$key = {
  readonly ' $data'?: NoCemeteries_cemeteries$data
  readonly ' $fragmentSpreads': FragmentRefs<'NoCemeteries_cemeteries'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'NoCemeteries_cemeteries',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'total',
      storageKey: null
    }
  ],
  type: 'CemeteryResult',
  abstractKey: null
}

;(node as any).hash = '2691ee8e28717bc151552e276ebefdd5'

export default node
