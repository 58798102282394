/**
 * @generated SignedSource<<854829e5a0efa4029e5b8b4107734a04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type useAveragePrices_averages$data = {
  readonly averages: ReadonlyArray<{
    readonly category_code: string
    readonly price: number | null
  }>
  readonly ' $fragmentType': 'useAveragePrices_averages'
}
export type useAveragePrices_averages$key = {
  readonly ' $data'?: useAveragePrices_averages$data
  readonly ' $fragmentSpreads': FragmentRefs<'useAveragePrices_averages'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'useAveragePrices_averages',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'PriceAverage',
      kind: 'LinkedField',
      name: 'averages',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'category_code',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'price',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'CemeteryPriceAverages',
  abstractKey: null
}

;(node as any).hash = '7e2bb598ffe718d005ddc4ed9fe2fa34'

export default node
