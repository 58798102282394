import { useRouter } from 'next/router'
import { listPathWithParamsForNextLink } from '@lifedot/router'
import { useListPage } from '@/hooks/use-list-page'

export const useNoCemeteriesParamsReplace = ({
  total
}: {
  total: number
}): void => {
  const router = useRouter()
  const {
    prefecture: { roma: prefectureRoma },
    searchParams: { cityIds, stationIds, conditions }
  } = useListPage()
  if (total < 1) {
    const [pathname] = listPathWithParamsForNextLink({
      prefectureRoma,
      cityId: cityIds,
      stationId: stationIds,
      condition: conditions
    })
    router.replace(pathname)
  }
}
