import { FC, useMemo } from 'react'
import { markup } from '@lifedot/utils'
import { HighScoreCemeteries_highScoreCemeteries$key } from './__generated__/HighScoreCemeteries_highScoreCemeteries.graphql'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { makeHighScoreCemeteriesMarkup } from './dependencies'

type HighScoreCemeteriesProps = {
  highScoreCemeteries: HighScoreCemeteries_highScoreCemeteries$key
}

export const HighScoreCemeteries: FC<HighScoreCemeteriesProps> = ({
  highScoreCemeteries
}) => {
  const data = useFragment(fragment, highScoreCemeteries)

  const filteredData = useMemo(
    () =>
      data.items.filter(
        ({ review_summary: review }) =>
          review.total_score && review.total_score >= 3
      ),
    [data]
  )
  const highScoreCemeteriesMarkup = makeHighScoreCemeteriesMarkup(filteredData)
  if (!highScoreCemeteriesMarkup) return null

  return <p dangerouslySetInnerHTML={markup(highScoreCemeteriesMarkup)} />
}

const fragment = graphql`
  fragment HighScoreCemeteries_highScoreCemeteries on CemeteryResult {
    items {
      cemeteryId: id
      name
      review_summary {
        count
        total_score
      }
    }
  }
`
