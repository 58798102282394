import { FC } from 'react'
import dynamic from 'next/dynamic'
import { MapProps } from './Map'

export const MapDynamic: FC = () => {
  const Map = dynamic<MapProps>(() => import('./Map').then((mod) => mod.Map), {
    loading: Loading
  })

  return <Map />
}

const Loading: FC = () => <p>Loading</p>
