import { FC } from 'react'
import { palette } from '@lifedot/styles/palette'
import { useAveragePrices_averages$key } from '@lifedot/hooks/use-average-prices/__generated__/useAveragePrices_averages.graphql'
import { PopularCemeteries_rankingCemeteries$key } from './__generated__/PopularCemeteries_rankingCemeteries.graphql'
import { HighScoreCemeteries_highScoreCemeteries$key } from './__generated__/HighScoreCemeteries_highScoreCemeteries.graphql'
import { css } from '@emotion/react'
import { SeeMore } from '@lifedot/components/SeeMore'
import { AveragePrice } from './AveragePrice'
import { PopularCemeteries } from './PopularCemeteries'
import { HighScoreCemeteries } from './HighScoreCemeteries'
import { ClosingText } from './ClosingText'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  root: css({
    fontSize: 14,
    [mq('sp')]: {
      padding: '0 8px'
    },
    a: {
      color: palette.main03
    }
  })
}

type PageDescriptionProps = {
  averages: useAveragePrices_averages$key
  rankingCemeteries: PopularCemeteries_rankingCemeteries$key
  highScoreCemeteries: HighScoreCemeteries_highScoreCemeteries$key
}

export const PageDescription: FC<PageDescriptionProps> = ({
  averages,
  rankingCemeteries,
  highScoreCemeteries
}) => {
  return (
    <SeeMore visibleHeight={120} isSiteBaseBackground>
      <div css={styles.root}>
        <AveragePrice averages={averages} />
        <PopularCemeteries rankingCemeteries={rankingCemeteries} />
        <HighScoreCemeteries highScoreCemeteries={highScoreCemeteries} />
        <ClosingText />
      </div>
    </SeeMore>
  )
}
