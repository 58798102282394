import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'

const styles = {
  root: css({
    color: palette.black
  })
}

export interface BreadCrumbProps {
  content?: number
  href: string
  name: string
}

export const BreadcrumbItem: FC<BreadCrumbProps> = (props) => {
  return (
    <a itemProp="item" css={styles.root} href={props.href}>
      <span itemProp="name">{props.name}</span>
      <meta itemProp="position" content={String(props.content)} />
    </a>
  )
}
