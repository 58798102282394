import { FC } from 'react'
import { markup } from '@lifedot/utils'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import { PopularCemeteries_rankingCemeteries$key } from './__generated__/PopularCemeteries_rankingCemeteries.graphql'
import { makePopularCemeteriesMarkup } from './dependencies'

type PopularCemeteriesProps = {
  rankingCemeteries: PopularCemeteries_rankingCemeteries$key
}

export const PopularCemeteries: FC<PopularCemeteriesProps> = ({
  rankingCemeteries
}) => {
  const { items } = useFragment(fragment, rankingCemeteries)
  const popularCemeteriesMarkup = makePopularCemeteriesMarkup(items)
  if (!popularCemeteriesMarkup) return null
  return <p dangerouslySetInnerHTML={markup(popularCemeteriesMarkup)} />
}

const fragment = graphql`
  fragment PopularCemeteries_rankingCemeteries on CemeteryResult {
    items {
      cemeteryId: id
      name
      nearest_station {
        distance
        name
      }
    }
  }
`
