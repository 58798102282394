import { FC } from 'react'
import {
  ResponsiveBanner,
  ResponsiveBannerProps
} from '@lifedot/components/ResponsiveBanner'
import { graphql } from 'relay-runtime'
import { PromotionBanner_banners$key } from './__generated__/PromotionBanner_banners.graphql'
import { useFragment } from 'react-relay/hooks'

export const PromotionBanner: FC<
  Pick<ResponsiveBannerProps, 'priority'> & {
    banners: PromotionBanner_banners$key
  }
> = ({ banners, priority }) => {
  const data = useFragment(fragment, banners)

  return (
    <ResponsiveBanner
      banners={data.items}
      pcSize={{ width: 1480, height: 250 }}
      spSize={{ width: 640, height: 121 }}
      priority={priority}
    />
  )
}

const fragment = graphql`
  fragment PromotionBanner_banners on BannerResult {
    items {
      alt
      desktopSrc: desktop_url
      link: link_url
      mobileSrc: mobile_url
    }
  }
`
