import { FC } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { ThirdPartyAdData_cemeteries$key } from './__generated__/ThirdPartyAdData_cemeteries.graphql'
import { markup } from '@lifedot/utils'
import { useListPage } from '@/hooks/use-list-page'
import { makeAdCemeteryData } from '@/components/ThirdPartyAdData/dependencies'

interface ThirdPartyAdDataProps {
  cemeteries: ThirdPartyAdData_cemeteries$key
}

export const ThirdPartyAdData: FC<ThirdPartyAdDataProps> = ({ cemeteries }) => {
  const { items } = useFragment(cemeteriesFragment, cemeteries)
  const { prefecture, city } = useListPage()

  return (
    <script
      type="application/json"
      id="adData"
      dangerouslySetInnerHTML={markup(
        JSON.stringify({
          prefecture,
          city,
          cemeteries: makeAdCemeteryData(items)
        })
      )}
    />
  )
}

const cemeteriesFragment = graphql`
  fragment ThirdPartyAdData_cemeteries on CemeteryResult {
    items {
      cemeteryId: id
      code
      best_price_by_category {
        normal {
          price1
          price1_unit
          price2
          purchase_price
          purchase_price_unit
          calculated_purchase_price
          category_code
          purchase_price_option
          price3
          simulated_price3
          price4
          price4_name
        }
        nokotsu {
          price1
          price1_unit
          price2
          purchase_price
          purchase_price_unit
          calculated_purchase_price
          category_code
          purchase_price_option
          price3
          simulated_price3
          price4
          price4_name
        }
        jumoku {
          price1
          price1_unit
          price2
          purchase_price
          purchase_price_unit
          calculated_purchase_price
          category_code
          purchase_price_option
          price3
          simulated_price3
          price4
          price4_name
        }
        eitai_kuyo {
          price1
          price1_unit
          price2
          purchase_price
          purchase_price_unit
          calculated_purchase_price
          category_code
          purchase_price_option
          price3
          simulated_price3
          price4
          price4_name
        }
      }
    }
  }
`
