/**
 * @generated SignedSource<<b5c5c85c285d2a0fb7d2bbc612b251d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type CemeteryList_cemeteries$data = {
  readonly items: ReadonlyArray<{
    readonly cemeteryId: number
    readonly name: string
    readonly kana: string | null
    readonly address: string | null
    readonly review_summary: {
      readonly total_score: number | null
      readonly count: number
      readonly valuable_review: {
        readonly age: number
        readonly gender: string
        readonly answers: ReadonlyArray<{
          readonly code: string
          readonly text: string | null
        }>
      } | null
    }
    readonly descriptions: ReadonlyArray<{
      readonly subject: string | null
    }>
    readonly director_comment: string | null
    readonly stations: ReadonlyArray<{
      readonly stationId: number
      readonly distance: number
      readonly name: string
      readonly nearest: boolean
    }>
    readonly section_types: ReadonlyArray<{
      readonly code: string
      readonly name: string
    }>
    readonly features: ReadonlyArray<{
      readonly name: string
    }>
    readonly cemetery_types: ReadonlyArray<{
      readonly name: string
      readonly code: string
    }>
    readonly sects: ReadonlyArray<{
      readonly name: string
      readonly code: string
    }>
    readonly main_image: {
      readonly path: string | null
      readonly alt: string | null
    } | null
    readonly sub_images: ReadonlyArray<{
      readonly path: string | null
      readonly alt: string | null
    }>
    readonly best_price_by_category: {
      readonly normal: {
        readonly price1: number | null
        readonly price1_unit: string | null
        readonly price2: number | null
        readonly price2_unit: string | null
        readonly purchase_price: number | null
        readonly purchase_price_unit: string | null
        readonly calculated_purchase_price: number | null
        readonly category_code: string
        readonly purchase_price_option: string
        readonly price3: number | null
        readonly simulated_price3: number | null
        readonly price4: number | null
        readonly price4_name: string | null
        readonly lot_area: number | null
      } | null
      readonly nokotsu: {
        readonly price1: number | null
        readonly price1_unit: string | null
        readonly price2: number | null
        readonly price2_unit: string | null
        readonly purchase_price: number | null
        readonly purchase_price_unit: string | null
        readonly calculated_purchase_price: number | null
        readonly category_code: string
        readonly purchase_price_option: string
        readonly price3: number | null
        readonly simulated_price3: number | null
        readonly price4: number | null
        readonly price4_name: string | null
        readonly lot_area: number | null
      } | null
      readonly jumoku: {
        readonly price1: number | null
        readonly price1_unit: string | null
        readonly price2: number | null
        readonly price2_unit: string | null
        readonly purchase_price: number | null
        readonly purchase_price_unit: string | null
        readonly calculated_purchase_price: number | null
        readonly category_code: string
        readonly purchase_price_option: string
        readonly price3: number | null
        readonly simulated_price3: number | null
        readonly price4: number | null
        readonly price4_name: string | null
        readonly lot_area: number | null
      } | null
      readonly eitai_kuyo: {
        readonly price1: number | null
        readonly price1_unit: string | null
        readonly price2: number | null
        readonly price2_unit: string | null
        readonly purchase_price: number | null
        readonly purchase_price_unit: string | null
        readonly calculated_purchase_price: number | null
        readonly category_code: string
        readonly purchase_price_option: string
        readonly price3: number | null
        readonly simulated_price3: number | null
        readonly price4: number | null
        readonly price4_name: string | null
        readonly lot_area: number | null
      } | null
    }
    readonly requestable: boolean
  }>
  readonly ' $fragmentType': 'CemeteryList_cemeteries'
}
export type CemeteryList_cemeteries$key = {
  readonly ' $data'?: CemeteryList_cemeteries$data
  readonly ' $fragmentSpreads': FragmentRefs<'CemeteryList_cemeteries'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'code',
      storageKey: null
    },
    v2 = [v0 /*: any*/, v1 /*: any*/],
    v3 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'path',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'alt',
        storageKey: null
      }
    ],
    v4 = [
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price1',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price1_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price2',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price2_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price_unit',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'calculated_purchase_price',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'category_code',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'purchase_price_option',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price3',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'simulated_price3',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price4',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'price4_name',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        kind: 'ScalarField',
        name: 'lot_area',
        storageKey: null
      }
    ]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'CemeteryList_cemeteries',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'Cemetery',
        kind: 'LinkedField',
        name: 'items',
        plural: true,
        selections: [
          {
            alias: 'cemeteryId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'kana',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'address',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryReviewSummary',
            kind: 'LinkedField',
            name: 'review_summary',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'total_score',
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'count',
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryReview',
                kind: 'LinkedField',
                name: 'valuable_review',
                plural: false,
                selections: [
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'age',
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    kind: 'ScalarField',
                    name: 'gender',
                    storageKey: null
                  },
                  {
                    alias: null,
                    args: null,
                    concreteType: 'CemeteryReviewAnswer',
                    kind: 'LinkedField',
                    name: 'answers',
                    plural: true,
                    selections: [
                      v1 /*: any*/,
                      {
                        alias: null,
                        args: null,
                        kind: 'ScalarField',
                        name: 'text',
                        storageKey: null
                      }
                    ],
                    storageKey: null
                  }
                ],
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryDescription',
            kind: 'LinkedField',
            name: 'descriptions',
            plural: true,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'subject',
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'director_comment',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryStation',
            kind: 'LinkedField',
            name: 'stations',
            plural: true,
            selections: [
              {
                alias: 'stationId',
                args: null,
                kind: 'ScalarField',
                name: 'id',
                storageKey: null
              },
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'distance',
                storageKey: null
              },
              v0 /*: any*/,
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'nearest',
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryChoice',
            kind: 'LinkedField',
            name: 'section_types',
            plural: true,
            selections: [v1 /*: any*/, v0 /*: any*/],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryChoice',
            kind: 'LinkedField',
            name: 'features',
            plural: true,
            selections: [v0 /*: any*/],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryChoice',
            kind: 'LinkedField',
            name: 'cemetery_types',
            plural: true,
            selections: v2 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryChoice',
            kind: 'LinkedField',
            name: 'sects',
            plural: true,
            selections: v2 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryImage',
            kind: 'LinkedField',
            name: 'main_image',
            plural: false,
            selections: v3 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryImage',
            kind: 'LinkedField',
            name: 'sub_images',
            plural: true,
            selections: v3 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryBestPriceByCategory',
            kind: 'LinkedField',
            name: 'best_price_by_category',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'normal',
                plural: false,
                selections: v4 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'nokotsu',
                plural: false,
                selections: v4 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'jumoku',
                plural: false,
                selections: v4 /*: any*/,
                storageKey: null
              },
              {
                alias: null,
                args: null,
                concreteType: 'CemeteryPriceAndSpec',
                kind: 'LinkedField',
                name: 'eitai_kuyo',
                plural: false,
                selections: v4 /*: any*/,
                storageKey: null
              }
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'requestable',
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'CemeteryResult',
    abstractKey: null
  }
})()

;(node as any).hash = 'c9bb464cd1f3dc73c163833988126dc4'

export default node
