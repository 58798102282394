/**
 * @generated SignedSource<<08127cb75b3c5855e92b19eba9be0ab2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type ConditionLinksList_conditions$data = {
  readonly items: ReadonlyArray<{
    readonly code: string
    readonly attributeCode: string
    readonly name: string
    readonly cemeteryCount: number
  }>
  readonly ' $fragmentType': 'ConditionLinksList_conditions'
}
export type ConditionLinksList_conditions$key = {
  readonly ' $data'?: ConditionLinksList_conditions$data
  readonly ' $fragmentSpreads': FragmentRefs<'ConditionLinksList_conditions'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ConditionLinksList_conditions',
  selections: [
    {
      alias: null,
      args: null,
      concreteType: 'SearchChoiceWithCemeteryCount',
      kind: 'LinkedField',
      name: 'items',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'code',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'attributeCode',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'cemeteryCount',
          storageKey: null
        }
      ],
      storageKey: null
    }
  ],
  type: 'SearchChoiceWithCemeteryCountConnection',
  abstractKey: null
}

;(node as any).hash = '9d95e1af1259b4d1511c3598a6de57d3'

export default node
