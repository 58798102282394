import { FC } from 'react'
import { ListItem } from '@lifedot/atoms/List'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { css } from '@emotion/react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { Ranking_rankingCemeteries$key } from './__generated__/Ranking_rankingCemeteries.graphql'
import { RankingWrapper } from '@lifedot/components/RankingWrapper'
import { CemeteryCard } from '@lifedot/components/CemeteryCard'

const styles = {
  root: css({
    [mq('sp')]: {
      margin: '0 12px'
    }
  }),
  title: css({
    marginBottom: 8,
    [mq('sp')]: {
      padding: '0 12px'
    }
  }),
  cemeteries: css({
    display: 'flex',
    overflow: 'scroll',
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '4px 4px 8px',
    '::-webkit-scrollbar': {
      height: 12
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: palette.gray3
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: palette.gray2,
      border: 'none'
    },
    'li:not(:first-of-type)': {
      marginLeft: 8
    },
    'li a': {
      width: 220
    }
  }),
  note: css({
    color: palette.gray6,
    marginTop: 4
  })
}

interface RankingProps {
  rankingCemeteries: Ranking_rankingCemeteries$key
}

export const Ranking: FC<RankingProps> = ({ rankingCemeteries }) => {
  const data = useFragment(fragment, rankingCemeteries)

  return (
    <>
      <h2 css={[typography.headingXS, styles.title]}>
        近隣霊園・墓地人気ランキング
      </h2>
      <div css={styles.root}>
        <ul css={styles.cemeteries}>
          {data.items.map((cemetery, index) => (
            <ListItem key={cemetery.cemeteryId}>
              <RankingWrapper rank={index + 1} key={index}>
                <CemeteryCard cemetery={cemetery} />
              </RankingWrapper>
            </ListItem>
          ))}
        </ul>
        <p css={[typography.textS, styles.note]}>
          ※当ランキングは、Life.内の該当エリアでよく見られている霊園を掲載しています。
        </p>
      </div>
    </>
  )
}

const fragment = graphql`
  fragment Ranking_rankingCemeteries on CemeteryResult {
    items {
      cemeteryId: id
      ...useCemeteryTinyItem_cemetery
    }
  }
`
