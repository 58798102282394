import { graphql } from 'relay-runtime'
import { useAveragePrices_averages$key } from './__generated__/useAveragePrices_averages.graphql'
import { useFragment } from 'react-relay/hooks'
import { makeItems, Item } from './dependencies'

export const useAveragePrices = (
  averages: useAveragePrices_averages$key
): Item => {
  const data = useFragment(fragment, averages)
  return makeItems(data)
}

const fragment = graphql`
  fragment useAveragePrices_averages on CemeteryPriceAverages {
    averages {
      category_code
      price
    }
  }
`
