import { FC } from 'react'
import { css } from '@emotion/react'
import { ResultCount } from './ResultCount'
import { SortList } from './SortList'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  wrap: css({
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    '> :last-of-type': {
      marginLeft: 12
    },
    [mq('sp')]: {
      paddingInline: 8
    }
  })
}

export const SearchResultStatus: FC = () => {
  return (
    <div css={styles.wrap}>
      <ResultCount />
      <SortList />
    </div>
  )
}
