import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import {
  useCemeteryTinyItem_cemetery$data,
  useCemeteryTinyItem_cemetery$key
} from './__generated__/useCemeteryTinyItem_cemetery.graphql'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  bestDisplayablePurchasePrices,
  cemeteryMainImage,
  DisplayablePurchasePrice
} from '../../../utils/src'

export const useCemeteryTinyItem = (
  cemetery: useCemeteryTinyItem_cemetery$key
): ReturnType<typeof makeItem> => {
  const data = useFragment(cemeteryFragment, cemetery)
  return makeItem(data)
}

const makeItem = (
  cemetery: useCemeteryTinyItem_cemetery$data
): {
  cemeteryId: number
  prefectureName: string
  cityName: string
  cemeteryName: string
  image: { alt: string; path: string }
  price?: DisplayablePurchasePrice
  labels: { name: string }[]
  review?: { score: number; count: number }
  directorComment: string
} => {
  const {
    cemeteryId,
    prefecture: { name: prefectureName },
    city: { name: cityName },
    name: cemeteryName,
    review_summary,
    main_image,
    cemetery_types,
    sects,
    section_types,
    director_comment
  } = cemetery

  const image = cemeteryMainImage(main_image)
  const labels = [
    ...section_types,
    ...cemetery_types.filter(({ code }) => code !== 'not_koei'),
    ...sects
  ]
  const price = bestDisplayablePurchasePrices(cemetery.best_price_by_category)
  const review = !review_summary.total_score
    ? undefined
    : { score: review_summary.total_score, count: review_summary.count }

  return {
    cemeteryId,
    prefectureName,
    cityName,
    cemeteryName,
    image,
    price,
    labels,
    review,
    directorComment: director_comment ?? ''
  }
}

const cemeteryFragment = graphql`
  fragment useCemeteryTinyItem_cemetery on CemeteryBase {
    cemeteryId: id
    name
    prefecture {
      name
    }
    city {
      name
    }
    review_summary {
      total_score
      count
    }
    director_comment
    main_image {
      alt
      path
    }
    section_types {
      name
    }
    cemetery_types {
      name
      code
    }
    sects {
      name
    }
    best_price_by_category {
      normal {
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
        lot_area
      }
      nokotsu {
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
        lot_area
      }
      jumoku {
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
        lot_area
      }
      eitai_kuyo {
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
        lot_area
      }
    }
  }
`
