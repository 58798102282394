const images = [
  'https://s3.lifedot.jp/uploads/ending/path/67879/icon_ranking_1.png',
  'https://s3.lifedot.jp/uploads/ending/path/67880/icon_ranking_2.png',
  'https://s3.lifedot.jp/uploads/ending/path/67873/icon_ranking_3.png',
  'https://s3.lifedot.jp/uploads/ending/path/67877/icon_ranking_4.png',
  'https://s3.lifedot.jp/uploads/ending/path/67872/icon_ranking_5.png',
  'https://s3.lifedot.jp/uploads/ending/path/67881/icon_ranking_6.png',
  'https://s3.lifedot.jp/uploads/ending/path/67876/icon_ranking_7.png',
  'https://s3.lifedot.jp/uploads/ending/path/67875/icon_ranking_8.png',
  'https://s3.lifedot.jp/uploads/ending/path/67878/icon_ranking_9.png',
  'https://s3.lifedot.jp/uploads/ending/path/67874/icon_ranking_10.png'
]

const fallbackImage =
  'https://s3.lifedot.jp/uploads/ending/path/67874/icon_ranking_10.png'

export const getRankImagePath = (rank: number): string =>
  images[rank - 1] ?? fallbackImage
