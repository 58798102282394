import { useListPage } from '@/hooks/use-list-page'

export const useDescription = (): string => {
  const { prefecture, city, station, condition } = useListPage()
  const place = station
    ? `${station.name}駅(${station.prefectureName}${station.cityName})`
    : `${prefecture.name}${city?.name ?? ''}`

  return `${place}のお墓${
    condition ? `(${condition.name})` : ''
  }一覧。お墓探しのlife.（ライフドット）は、${place}の霊園・墓地の中からおすすめのお墓や、ご自宅近くの樹木葬・納骨堂・永代供養墓・一般墓（墓石を建てるお墓）をご提案します。地域別の墓地一覧、費用相場、${
    condition ? `お墓(${condition.name})の` : ''
  }人気ランキングなど、お墓選びに役立つ情報が満載。お墓の評判・口コミや、詳細な交通アクセス・地図、料金・値段もご覧いただけます。${
    condition ? `希望する条件「${condition.name}」や、` : ''
  }民営霊園・公営霊園（市営・都立・都営）・有名寺院、宗教・宗派、ペット供養など、さまざまな特徴から比較して${place}のお墓を探せます。お墓の見学予約・資料請求の申込みのほか、墓石購入、お墓の移設、墓じまい後の遺骨の移動先・移す方法についても気軽にご相談ください。`
}
