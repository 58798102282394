/**
 * @generated SignedSource<<aa57bdc8af145f45c40cafc41871b0e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type PopularCemeteries_rankingCemeteries$data = {
  readonly items: ReadonlyArray<{
    readonly cemeteryId: number
    readonly name: string
    readonly nearest_station: {
      readonly distance: number | null
      readonly name: string | null
    }
  }>
  readonly ' $fragmentType': 'PopularCemeteries_rankingCemeteries'
}
export type PopularCemeteries_rankingCemeteries$key = {
  readonly ' $data'?: PopularCemeteries_rankingCemeteries$data
  readonly ' $fragmentSpreads': FragmentRefs<'PopularCemeteries_rankingCemeteries'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'name',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'PopularCemeteries_rankingCemeteries',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'Cemetery',
        kind: 'LinkedField',
        name: 'items',
        plural: true,
        selections: [
          {
            alias: 'cemeteryId',
            args: null,
            kind: 'ScalarField',
            name: 'id',
            storageKey: null
          },
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryNearestStation',
            kind: 'LinkedField',
            name: 'nearest_station',
            plural: false,
            selections: [
              {
                alias: null,
                args: null,
                kind: 'ScalarField',
                name: 'distance',
                storageKey: null
              },
              v0 /*: any*/
            ],
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'CemeteryResult',
    abstractKey: null
  }
})()

;(node as any).hash = '9893244f8128a3e03f21b0b321afd007'

export default node
