import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { FC } from 'react'

const styles = css({
  color: palette.gray6,
  marginTop: 4,
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
  [mq('sp')]: {
    WebkitLineClamp: 3
  }
})

interface DescriptionProps {
  text: string
}

export const Description: FC<DescriptionProps> = ({ text }) => (
  <p css={[typography.textS, styles]}>{text}</p>
)
